import styled from "styled-components";

interface RotationContainerProps {
  data: {
    startRotationDegrees: number;
    finalRotationDegrees: number;
    startSpinningTime: number;
    continueSpinningTime: number;
    stopSpinningTime: number;
    disableInitialAnimation: boolean;
    time: string;
  };
}

export const RotationContainer = styled.div<RotationContainerProps>`
  display: flex;
  border-radius: 50%;
  z-index: 1;

  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => props.data.startRotationDegrees}deg);

  &.started-spinning {
    animation: spin-${({ data }) => data.time} ${({ data }) =>
          data.startSpinningTime / 1000}s cubic-bezier(
          0.71,
          ${(props) => (props.data.disableInitialAnimation ? 0 : -0.29)},
          0.96,
          0.9
        ) 0s 1 normal forwards running,
      continueSpin-${({ data }) => data.time} ${({ data }) =>
          data.continueSpinningTime / 1000}s linear ${({ data }) =>
          data.startSpinningTime / 1000}s 1 normal forwards running,
      stopSpin-${({ data }) => data.time} ${({ data }) =>
          data.stopSpinningTime / 1000}s cubic-bezier(0, 0, 0.05, 1.02) ${({
          data,
        }) => (data.startSpinningTime + data.continueSpinningTime) / 1000}s 1 normal
        forwards running;
  }

  @keyframes spin-${({ data }) => data.time} {
    from {
      transform: rotate(${(props) => props.data.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.data.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin-${({ data }) => data.time} {
    from {
      transform: rotate(${(props) => props.data.startRotationDegrees}deg);
    }
    to {
      transform: rotate(
        ${(props) => props.data.startRotationDegrees + 1440}deg
      );
    }
  }
  @keyframes stopSpin-${({ data }) => data.time} {
    from {
      transform: rotate(${(props) => props.data.startRotationDegrees}deg);
    }
    to {
      transform: rotate(
        ${(props) => 1440 + props.data.finalRotationDegrees}deg
      );
    }
  }
`;

// function getHslFromColor(color: string) {
//   if (color === "blue") {
//     return "sepia(100%) saturate(1000%) brightness(50%) hue-rotate(190deg);";
//   }

//   if (color === "gold") {
//     return "sepia(100%) saturate(250%) brightness(80%) hue-rotate(370deg);";
//   }

//   if (color === "purple") {
//     return "sepia(100%) saturate(1000%) brightness(50%) hue-rotate(210deg);";
//   }

//   if (color === "pink") {
//     return "sepia(100%) saturate(500%) brightness(50%) hue-rotate(240deg);";
//   }

//   if (color === "red") {
//     return "sepia(100%) saturate(1400%) brightness(50%) hue-rotate(320deg);";
//   }
// }


export const RotationLight = styled.img<{
  totalSpinningTime: number;
  color: "blue" | "gold" | "purple" | "pink" | "red";
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  pointer-events: none;
  width: 100%;
  height: 100%;

  &.started-spinning {
    animation: light-spin
      ${({ totalSpinningTime }) => totalSpinningTime / 1000}s linear 0s 1 normal
      forwards running;
  }

  &.inf-spin {
    animation: light-spin 15s linear infinite;
  }

  @keyframes light-spin {
    from {
      transform: translate(-50%, -50%) scale(1.3) rotate(360deg);
    }
    to {
      transform: translate(-50%, -50%) scale(1.3) rotate(0deg);
    }
  }

`;
