import { Button, ButtonSize } from "./button";
import { buttonColors } from "./button/Button.types";

interface Props {
  maxDivisions: number;
  divisions: number;
  onClick: () => void;
  addedNew?: boolean;
  icon: string;
  ignoreGradient?: boolean;
  isLoading?: boolean;
}
export function ProgressItem(props: Props) {
  const {
    maxDivisions,
    divisions,
    onClick,
    addedNew,
    icon,
    ignoreGradient,
    isLoading,
  } = props;
  return (
    <Button
      size={ButtonSize.small}
      buttonColor={
        divisions < maxDivisions || ignoreGradient
          ? buttonColors.black
          : "linear-gradient(to bottom, #04ECEE, #7034FF);"
      }
      shadowColor={buttonColors.default}
      height="65px"
      onClick={onClick}
    >
      <img
        src={icon}
        className="h-10"
        style={{ transform: icon === "/rocket.png" ? "rotate(45deg)" : "" }}
      />
      <Button
        size={ButtonSize.small}
        shadow={false}
        maxWidth={false}
        style={{ width: 105 }}
      >
        {!isLoading ? (
          <div className="flex flex-row w-full bg-purple-light h-10 justify-center items-center px-2 py-2 gap-2">
            {divisions < maxDivisions && false ? (
              Array(maxDivisions)
                .fill(0)
                .map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-full h-full rounded-sm ${
                        index < divisions
                          ? addedNew && index === divisions - 1
                            ? "bg-gradient-to-b from-button-division3 to-button-division4"
                            : "bg-gradient-to-b from-button-division1 to-button-division2"
                          : "bg-button-black"
                      }`}
                    ></div>
                  );
                })
            ) : (
              <>
                {divisions >= maxDivisions && (
                  <img src="/mark.svg" className="h-3 object-contain" />
                )}
                <div className="text-white text-sm font-medium">{`${divisions}/${maxDivisions}`}</div>
              </>
            )}
          </div>
        ) : (
          <div>Loading</div>
        )}
      </Button>
    </Button>
  );
}
