import styled from "styled-components";

export const CaseRoller = styled.div`
  display: flex;
  gap: 5px;
  margin: 20px 0;
  margin-left: 0px;
  transition: margin-left 10s cubic-bezier(0.23, 0.78, 0.29, 1);
  position: relative;
`;

export const CaseScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(transparent 50%, black);
`;

export const CaseDivider = styled.div`
  position: absolute;
  width: 1px;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(
    transparent,
    #1d1d1d 10%,
    #1d1d1d 90%,
    transparent
  );
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.75);
`;

export const ItemContainer = styled.div<{ size: string }>`
  width: ${(props) => props.size || "100px"};
  height: ${(props) => props.size || "100px"};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0 #000;
  flex-shrink: 0;
`;

export const Item = styled.div`
  position: relative;
  flex-shrink: 0;
  flex: 1;
  background: radial-gradient(
    circle at 50% 110%,
    #c7c7c7,
    ${makeColorLighter(-40, "#c7c7c7")}
  );
`;

export const ImgContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 34px);
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    background-size: contain;
    object-fit: contain;
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5px;
  }

  .circle-shadow {
    position: absolute;
    width: 60px;
    height: 10px;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3) 50%,
      transparent
    );
  }
`;

interface InfoContainerProps {
  rarity: string;
}

export const InfoContainer = styled.div<InfoContainerProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34px;

  .info {
    height: 100%;
    padding: 1px 4px;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #fff;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.85);
    box-sizing: border-box;

    ${({ rarity }) => {
      let rarityBgColor: string;
      switch (rarity) {
        case "gray":
          rarityBgColor = "#9fa8b0";
          break;
        case "blue":
          rarityBgColor = "#335ec3";
          break;
        case "purple":
          rarityBgColor = "#8058b5";
          break;
        case "pink":
          rarityBgColor = "#c921cf";
          break;
        case "red":
          rarityBgColor = "#b83131";
          break;
        case "gold":
          rarityBgColor = "#d4a900";
          break;
        default:
          rarityBgColor = "transparent";
          break;
      }

      return `
        background: linear-gradient(to right, ${rarityBgColor}, ${makeColorLighter(
        -30,
        rarityBgColor
      )});
      `;
    }}

    .name {
      font-weight: 500;
    }

    .weapon {
      margin-top: -3px;
      // Add weapon styles here
    }
  }
`;

function makeColorLighter(lighterPercentage: number, color: string) {
  const colorNumber = parseInt(color.replace("#", ""), 16);

  let multiplier = 1 + lighterPercentage / 100;
  if (lighterPercentage < 0) {
    multiplier = 1 - Math.abs(lighterPercentage) / 100;
  }

  const r = (colorNumber >> 16) * multiplier;
  const g = ((colorNumber >> 8) & 0x00ff) * multiplier;
  const b = (colorNumber & 0x0000ff) * multiplier;

  return `#${((r << 16) | (g << 8) | b).toString(16)}`;
}
