import { useEffect, useState } from "react";
import { CaseDivider, CaseRoller, CaseScreen } from "./style";
import { ItemContainer, Item, ImgContainer, InfoContainer } from "./style";
import { itemProps } from "./config";
import { images } from "../roulette/wheel";
import { prizes } from "../config";
import { WheelPrize } from "../../logic/types";

interface Props {
  onClose: () => void;
  wheelPrize: WheelPrize;
}

const itemWidthActual = 140;
let newResult = 0;
export function OpeningCase(props: Props) {
  const { onClose, wheelPrize } = props;

  const [margin, setMargin] = useState(0);
  const [result, setResult] = useState(0);
  let [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (!wheelPrize || wheelPrize.isRocketLoading || !wheelPrize.item_id)
      return;

    const chances: { [key: string]: number } = {
      blue: 65,
      purple: 30,
      pink: 4,
      red: 0.75,
      gold: 0.25,
    };

    const totalChances: number = Object.values(chances).reduce(
      (acc, curr) => acc + curr,
      0
    );

    for (let i = 0; i < 100; i++) {
      const resultIndex = Math.floor(Math.random() * totalChances);
      let cumulativeChance = 0;
      let raritySelected: any;

      for (const rarity in chances) {
        cumulativeChance += chances[rarity];
        if (resultIndex < cumulativeChance) {
          raritySelected = rarity;
          break;
        }
      }

      const filteredItems = Object.values(itemProps).filter(
        (item: any) => item.rarity === raritySelected
      );
      const randomItem: any =
        filteredItems[Math.floor(Math.random() * filteredItems.length)];

      items.push(randomItem);
    }

    const itemWidth = itemWidthActual + 5;
    // random from 40 to 60
    const resultIndex = Math.floor(Math.random() * 20) + 40;

    items[resultIndex + 1] = {
      ...prizes[wheelPrize.item_id],
      weapon: "Rocket",
    };

    setResult(resultIndex);

    const offsetInsideItem =
      (itemWidth / 2) * Math.random() * 0.95 * (Math.random() > 0.5 ? 1 : -1);
    const result = itemWidth * resultIndex + 40 - offsetInsideItem;
    newResult = -result;

    setItems(items);

    const timer = setTimeout(() => {
      onClose();
    }, 10500);

    return () => {
      clearTimeout(timer);
    };
  }, [wheelPrize]);

  useEffect(() => {
    setTimeout(() => {
      setMargin(newResult);
    }, 100);
  }, [items]);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full h-48">
      <div className="w-full bg-black overflow-hidden">
        <div className="w-full h-full select-none">
          {items.length && (
            <CaseRoller style={{ marginLeft: margin }}>
              {items.map((data, index) => {
                return (
                  <ItemContainer
                    size={`${itemWidthActual}px`}
                    key={index}
                    style={{}}
                  >
                    <Item>
                      <ImgContainer>
                        <div className="circle-shadow" />
                        <img src={data.image} className="img" />
                      </ImgContainer>
                      <InfoContainer rarity={data.rarity}>
                        <div className="info">
                          <div className="name">{data.weapon}</div>
                          <div className="weapon">{data.name}</div>
                        </div>
                      </InfoContainer>
                    </Item>
                  </ItemContainer>
                );
              })}
            </CaseRoller>
          )}
          <CaseDivider />
          <CaseScreen />
        </div>
      </div>
    </div>
  );
}
