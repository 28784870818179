import styled from "styled-components";
import {
  ButtonForm,
  ButtonSize,
  ButtonVariant,
  buttonColors,
} from "./Button.types";

interface ButtonProps {
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $disabled?: boolean;
  $maxWidth?: boolean;
  $form?: ButtonForm;
  $color?: string;
}

interface ButtonShadowProps {
  $shadow?: boolean;
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $color?: string;
  $form?: ButtonForm;
}

const divider = 2;
const buttonSize = {
  small: {
    height: "38px",
    width: "80px",
    maxWidth: "80px",
    clipPath: `polygon(
        0% 12%,
        2% 12%,
        2% 6%,
        4% 6%,
        4% 0%,
        96% 0%,
        96% 6%,
        98% 6%,
        98% 12%,
        100% 12%,
        100% 88%,
        98% 88%,
        98% 94%,
        96% 94%,
        96% 100%,
        4% 100%,
        4% 94%,
        2% 94%,
        2% 88%,
        0% 88%,
        0% 12%
    )`,
  },
  default: {
    height: "50px",
    width: "250px",
    maxWidth: "310px",
    clipPath: `polygon(
        0% 12%,
        1% 12%,
        1% 6%,
        2% 6%,
        2% 0%,
        98% 0%,
        98% 6%,
        99% 6%,
        99% 12%,
        100% 12%,
        100% 88%,
        99% 88%,
        99% 94%,
        98% 94%,
        98% 100%,
        2% 100%,
        2% 94%,
        1% 94%,
        1% 88%,
        0% 88%,
        0% 12%
    )`,
  },
  large: {
    height: "50px",
    width: "310px",
    maxWidth: "360px",
    clipPath: `polygon(
        0% 12%,
        1% 12%,
        1% 6%,
        2% 6%,
        2% 0%,
        98% 0%,
        98% 6%,
        99% 6%,
        99% 12%,
        100% 12%,
        100% 88%,
        99% 88%,
        99% 94%,
        98% 94%,
        98% 100%,
        2% 100%,
        2% 94%,
        1% 94%,
        1% 88%,
        0% 88%,
        0% 12%
    )`,
  },
  [ButtonForm.square]: {
    clipPath: `polygon(
            0% ${12 / 1}%,
            1% ${12 / 1}%,
            1% ${6 / 1}%,
            2% ${6 / 1}%,
            2% 0%,
            98% 0%,
            98% ${6 / 1}%,
            99% ${6 / 1}%,
            99% ${12 / 1}%,
            100% ${12 / 1}%,
            100% ${88 / 1}%,
            99% ${88 / 1}%,
            99% ${94 / 1}%,
            98% ${94 / 1}%,
            98% 100%,
            2% 100%,
            2% ${94 / 1}%,
            1% ${94 / 1}%,
            1% ${88 / 1}%,
            0% ${88 / 1}%,
            0% ${12 / 1}%
        )`,
  },
};

export const ButtonContainer = styled.div<ButtonShadowProps>`
  background: none;
  border: none;
  position: relative;
  width: 100%;
`;

export const ButtonShadow = styled.div<ButtonShadowProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(4px, 4px);

  background: ${({ $variant = ButtonVariant.primary, $shadow, $color }) => {
    if (!$shadow) {
      return "none";
    }
    if ($color) {
      return $color;
    }
    switch ($variant) {
      case ButtonVariant.primary:
        return buttonColors.shadow;
      case ButtonVariant.secondary:
        return buttonColors.shadow;
      case ButtonVariant.tertiary:
        return buttonColors.redShadow;
      default:
        return buttonColors.shadow;
    }
  }};
  clip-path: ${({ $size = ButtonSize.default, $form }) => {
    if ($form === ButtonForm.square) return buttonSize[$form].clipPath;
    return buttonSize[$size].clipPath;
  }};
`;

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: ${({ $size = ButtonSize.default }) => buttonSize[$size].height};
  min-width: ${({ $size = ButtonSize.default, $form }) => {
    if ($form === ButtonForm.square) return "auto";
    return buttonSize[$size].width;
  }};
  width: ${({ $form = ButtonForm.default }) =>
    $form === ButtonForm.square ? "auto" : "100%"};
  max-width: ${({ $size = ButtonSize.default, $maxWidth, $form }) => {
    if ($form === ButtonForm.square) return "auto";
    return $maxWidth ? buttonSize[$size].maxWidth : "100%";
  }};
  clip-path: ${({ $size = ButtonSize.default, $form }) => {
    if ($form === ButtonForm.square) return buttonSize[$form].clipPath;
    return buttonSize[$size].clipPath;
  }};
  aspect-ratio: ${({ $form = ButtonForm.default }) =>
    $form === ButtonForm.square ? "1/1" : "auto"};
  border: none;
  font-weight: 700;
  color: ${({ $variant = ButtonVariant.primary }) => {
    switch ($variant) {
      case ButtonVariant.primary:
        return buttonColors.textPrimary;
      case ButtonVariant.secondary:
        return buttonColors.textSecondary;
      case ButtonVariant.tertiary:
        return buttonColors.textPrimary;
      default:
        return buttonColors.textPrimary;
    }
  }};
  background: ${({ $variant = ButtonVariant.primary, $color }) => {
    if ($color) return $color;
    switch ($variant) {
      case ButtonVariant.primary:
        return buttonColors.primary;
      case ButtonVariant.secondary:
        return buttonColors.secondary;
      case ButtonVariant.tertiary:
        return buttonColors.buttonTertiary;
      default:
        return buttonColors.primary;
    }
  }};
  cursor: pointer;


  &.disabled {
    cursor: not-allowed;
    color: ${({ $variant = ButtonVariant.primary }) =>
      $variant === ButtonVariant.primary
        ? buttonColors.textPrimaryDark
        : buttonColors.textSecondaryDark};
    background: ${({ $variant = ButtonVariant.primary, $color }) => {
      if ($color) return $color;
      switch ($variant) {
        case ButtonVariant.primary:
          return buttonColors.primaryDark;
        case ButtonVariant.secondary:
          return buttonColors.secondaryDark;
        default:
          return buttonColors.primaryDark;
      }
    }};

    :hover {
      background: ${({ $variant = ButtonVariant.primary }) =>
        $variant === ButtonVariant.primary
          ? buttonColors.primaryDark
          : buttonColors.secondaryDark};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
