
import server from "../logic/server";
import { WheelPrize } from "../logic/types";
import { Header } from "./Header";
import { OpeningCase } from "./case/OpeningCase";

interface Props {
  setPage: (page: string) => void;
  wheelPrize?: WheelPrize;
}

export function Case(props: Props) {
  const { setPage, wheelPrize } = props;

  const onClose = () => {
    setPage("prize");
    server.onRocketCaseEnd?.();
  }

  return (
    <>
      <Header title="Opening Case" onBack={onClose} />
      <div
        className="flex flex-col justify-center items-center w-full p-4 h-max p-0 relative"
        style={{
          marginTop: "70px",
        }}
      >
        {!wheelPrize || !wheelPrize.item_id || wheelPrize.isRocketLoading ? (
          <div className="text-white text-center">Loading...</div>
        ) : (
          <OpeningCase
            wheelPrize={wheelPrize}
            onClose={onClose}
          />
        )}
      </div>
    </>
  );
}
