import React, { forwardRef, useMemo } from "react";
import {
  ButtonContainer,
  ButtonShadow,
  ButtonWrapper,
  IconWrapper,
} from "./Button.styles";
import { ButtonForm, ButtonProps, ButtonSize } from "./Button.types";

export const Button = (props: ButtonProps) => {
  const {
    icon,
    variant,
    children,
    size = ButtonSize.large,
    shadow = true,
    maxWidth = false,
    form = ButtonForm.default,
    height,
    buttonColor,
    shadowColor,
    ...buttonProps
  } = props;

  return (
    <ButtonContainer {...buttonProps} $size={size}>
      <ButtonShadow
        $variant={variant}
        $shadow={shadow}
        $color={shadowColor}
        $form={form}
      />
      <ButtonWrapper
        $variant={variant}
        $size={size}
        $form={form}
        $maxWidth={maxWidth}
        $color={buttonColor}
        style={{
          height,
          width: form === ButtonForm.square ? height : undefined,
          minWidth: form === ButtonForm.square ? height : undefined,
        }}
      >
        {children}
      </ButtonWrapper>
    </ButtonContainer>
  );
};