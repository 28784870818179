import { Header } from "./Header";
import { ProgressItem } from "./ProgressItem";
import { Button } from "./button";
import { OpeningCase } from "./case/OpeningCase";
import { RotationLight } from "./roulette/style";
import { useEffect, useState } from "react";
import { prizes } from "./config";
import { Wheel, WheelPrize } from "../logic/types";
import server from "../logic/server";

interface Props {
  setPage: (page: string) => void;
  wheelPrize?: WheelPrize;
  wheel?: Wheel;
  setWheelPrize?: (wheelPrize: WheelPrize) => void;
}

export function Prize(props: Props) {
  let [item, setItem] = useState<any>({});
  const { setPage, setWheelPrize, wheelPrize, wheel } = props;

  useEffect(() => {
    if (wheelPrize?.item_id) {
      setItem(prizes[wheelPrize.item_id]);
    }
  }, [wheelPrize]);

  const onClose = () => {
    if (setWheelPrize)
      setWheelPrize({
        item_id: undefined,
        isLoading: false,
        isRocketLoading: false,
      });
    setPage("wheel");
  };

  return (
    <>
      <Header title="" onBack={onClose} />
      <div
        className="select-none text-white flex flex-col relative justify-center items-center w-max h-max p-16 relative bg-[url('/public/bg-wheel.png')] bg-contain bg-no-repeat"
        style={{
          marginTop: "40px",
          backgroundSize: "100% 100%",
        }}
      >
        <RotationLight
          src={item?.rarity ? `/g-${item.rarity}.png` : "/g-blue.png"}
          color={item?.rarity || "blue"}
          className={"inf-spin"}
          totalSpinningTime={100000}
          style={{ zIndex: 1 }}
        />
        <img
          src={item?.image}
          alt={item?.name}
          style={{ transform: "rotate(20sdeg)" }}
          className="w-40 h-40 object-contain z-10"
        />
      </div>
      <div className="text-white mt-4 select-none">{"You've received"}</div>
      <div
        className="select-none text-white text-2xl font-medium mt-1"
        style={{ color: item.color ? item.color : "#fff" }}
      >
        {item?.name}
        {item?.rarity && "'s Rocket"}
      </div>
      {item?.text?.length && (
        <div className="text-white text-sm">{item.text ? item.text : ""}</div>
      )}

      <div className="select-none w-48 mt-4">
        {item?.name === "Rocket Part" && (
          <ProgressItem
            icon={item.image}
            maxDivisions={3}
            divisions={wheel?.res_rocket_part_amount || 0}
            addedNew={true}
            onClick={() => {
              if (
                wheel?.res_rocket_part_amount &&
                wheel.res_rocket_part_amount >= 3
              ) {
                server.onRocketCaseOpen();
                setPage("case");
              }
            }}
          />
        )}
        {(item?.name === "Lootbox Part" || item?.name === 'Lootbox') && (
          <ProgressItem
            icon={item.image}
            maxDivisions={5}
            divisions={wheel?.res_case_part_amount || 0}
            addedNew={true}
            isLoading={wheelPrize?.isLootboxLoading}
            onClick={() => {
              if (wheel?.res_case_part_amount && wheel.res_case_part_amount >= 5)
                server.onLootboxOpen(() => setPage("prize"));
            }}
          />
        )}
      </div>

      <div
        className="w-full"
        style={{
          marginTop:
            item?.name === "Rocket Part" || item?.name === "Lootbox Part" || item?.name === "Lootbox"
              ? "30px"
              : item.text
              ? "70px"
              : "90px",
        }}
      >
        <Button color="#ffffff" onClick={onClose}>
          <span className="text-white select-none">Awesome!</span>
        </Button>
      </div>
    </>
  );
}
