interface Props {
  icon: string;
  onClick: () => void;
}

export function TopButton(props: Props) {
  const { icon, onClick } = props;

  return (
    <button
      className=""
      onClick={onClick}
    >
      <img src={icon} alt="icon" className="h-full w-full" />
    </button>
  );
}
