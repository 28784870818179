import { useEffect, useRef, useState } from "react";
import { RotationContainer } from "./style";
import { getRotationDegrees } from "./utils";
import { imagesToLoad, preloadChill, prizes } from "../config";

export const images: {
  "/wheel.png"?: HTMLImageElement;
  "/rocket.png"?: HTMLImageElement;
  "/colornick.png"?: HTMLImageElement;
  "/lootbox.png"?: HTMLImageElement;
  "/punk.png"?: HTMLImageElement;
  "/spaceship-1.png"?: HTMLImageElement;
} = {};

const totalImages = imagesToLoad.length;

// SECTORS
const sectors = 12;
const colors = [
  "#3B05B8",
  "#2D0291",
  "#5C02B5",
  "#3B05B8",
  "#2D0291",
  "#5C02B5",
  "#3B05B8",
  "#2D0291",
  "#5C02B5",
  "#3B05B8",
  "#2D0291",
  "#5C02B5",
];

const imageSectors = [
  prizes[1].image, //1
  prizes[10].image, //2
  prizes[2].image, //3
  prizes[1].image, //4
  prizes[2].image, //5
  prizes[1].image, //6
  prizes[4].image, //7
  prizes[1].image, //8
  prizes[2].image, //9
  prizes[1].image, //10
  prizes[2].image, //11
  prizes[11].image, //12
];

const colorNicknames = [11, 12, 13, 14, 15];
function getSectorByItemId(itemId: number) {
  if (itemId === 1) return [1, 4, 6, 8, 10][Math.floor(Math.random() * 5)];

  if (itemId === 2) return [3, 5, 9, 11][Math.floor(Math.random() * 4)];

  if (colorNicknames.includes(itemId)) return 12;

  if (itemId === 10) return 2;

  if (itemId === 4) return 7;

  return 0;
}

const defaultWidth = 90;
const defaultHeight = 90;
const imagesSize: any = {
  "/rocket.png": {
    width: defaultWidth + 10,
    height: defaultHeight + 10,
  },
  "/punk.png": {
    width: defaultWidth - 10,
    height: defaultHeight - 10,
  },
  "/lootbox.png": {
    width: defaultWidth - 5,
    height: defaultHeight - 5,
  },
  "/colornick.png": {
    width: defaultWidth,
    height: defaultHeight,
  },
  "/wheel.png": {
    width: defaultWidth,
    height: defaultHeight,
  },
};

const STARTED_SPINNING = "started-spinning";

const START_SPINNING_TIME = 7000;
const CONTINUE_SPINNING_TIME = 4000;
const STOP_SPINNING_TIME = 35000;
const DEFAULT_SPIN_DURATION = 1.0;
const classKey = "1";
const spinDuration = 0.3;
const normalizedSpinDuration = Math.max(0.01, spinDuration);
const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;
export const totalSpinningTime =
  startSpinningTime + continueSpinningTime + stopSpinningTime;

let isImagesPreloadingStarted = false;
export const isImagesLoaded = { isLaoded: false };

interface Props {
  onStopSpinning: () => void;
  mustStartSpinning: boolean;
  prizeNumber: number;
  spinDuration: number;
  isUpdate: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
  hasStoppedSpinning: boolean;
  setHasStoppedSpinning: (hasStoppedSpinning: boolean) => void;
  startingOptionIndex?: number;
}

let timer: any;

let loaded = 0;
export function Roulette(props: Props) {
  const {
    onStopSpinning,
    mustStartSpinning,
    spinDuration,
    isUpdate,
    startingOptionIndex,
    setIsLoaded,
    prizeNumber,
    hasStoppedSpinning,
    setHasStoppedSpinning,
  } = props;

  const mainContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rotationContainerRef = useRef<HTMLDivElement>(null);
  let [isSpinning, setIsSpinning] = useState(false);
  const mustStopSpinning = useRef<boolean>(false);
  const [startRotationDegrees, setStartRotationDegrees] = useState(0);
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [rouletteUpdater, setRouletteUpdater] = useState(false);
  const [loadedImagesCounter, setLoadedImagesCounter] = useState(0);
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  let [data, setData] = useState<any>({});

  useEffect(() => {
    if (isImagesPreloadingStarted === false) {
      isImagesPreloadingStarted = true;
      imagesToLoad.forEach((image) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          setLoadedImagesCounter((prev) => prev + 1);
        };

        (images as any)[image] = img;
      });

      preloadChill.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    }
  }, []);

  const [size, setSize] = useState<number>(300);
  useEffect(() => {
    const handleResize = () => {
      if (mainContainerRef.current) {
        setSize(mainContainerRef.current.clientWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(loadedImagesCounter, totalImages);
    if (loadedImagesCounter === totalImages) {
      isImagesLoaded.isLaoded = true;
    }

    const canvas = canvasRef.current;
    if (
      !canvas ||
      (!isImagesLoaded.isLaoded && loadedImagesCounter < totalImages)
    )
      return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = centerX - 10;
    let startAngle = 0;

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < sectors; i++) {
        const arc = (Math.PI * 2) / sectors;
        const endAngle = startAngle + arc;

        ctx.fillStyle = colors[i];
        ctx.strokeStyle = "#6262F9";
        ctx.lineWidth = 8;

        ctx.closePath();
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        if (radius < 0) return;

        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.fill();
        ctx.closePath();
        ctx.stroke();
        ctx.save();

        ctx.translate(
          centerX + Math.cos(startAngle + arc / 2) * radius,
          centerY + Math.sin(startAngle + arc / 2) * radius
        );

        let contentRotationAngle = startAngle + arc / 2 + Math.PI / 2;

        ctx.rotate(contentRotationAngle);

        const image = (images as any)[imageSectors[i]];
        const width = (imagesSize[imageSectors[i]].width * size) / 400;
        const height = (imagesSize[imageSectors[i]].height * size) / 400;

        ctx.drawImage(
          image,
          (width + (image.offsetX || 0)) / -2,
          -(width - (180 * size) / 400 + (image?.offsetY || 0)) / 2,
          width,
          height
        );

        ctx.restore();

        startAngle = endAngle;
      }

      // add tex

      if (!images["/wheel.png"]) return;

      ctx.drawImage(
        images["/wheel.png"],
        centerX - (150 * size) / 400 / 2,
        centerY - (150 * size) / 400 / 2,
        (150 * size) / 400,
        (150 * size) / 400
      );
    };

    draw();

    // setStartingOption(10);
    setIsLoaded(true);
    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [rouletteUpdater, size, loadedImagesCounter, isUpdate, canvasRef.current]);

  useEffect(() => {
    if (mustStartSpinning === true && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
      const selectedPrize = getSectorByItemId(prizeNumber);
      const finalRotationDegreesCalculated = getRotationDegrees(
        selectedPrize,
        sectors
      );

      setFinalRotationDegrees(finalRotationDegreesCalculated);

      console.log(
        "finalRotationDegreesCalculated",
        finalRotationDegreesCalculated,
        "prize",
        prizeNumber,
        "sector",
        selectedPrize
      );
      setData({
        startRotationDegrees,
        finalRotationDegrees: finalRotationDegreesCalculated,
        startSpinningTime,
        continueSpinningTime,
        stopSpinningTime,
        disableInitialAnimation: false,
        time: classKey,
      });
    }
  }, [mustStartSpinning]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      console.log("STOPPING SPINNING");
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
      setHasStartedSpinning(false);
      onStopSpinning();
    }
  }, [hasStoppedSpinning]);

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING;
    }
    return "";
  };

  const startSpinning = () => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
      }
    }, totalSpinningTime);

    return () => {
      if (timer) clearTimeout(timer);
    };
  };

  const setStartingOption = (optionIndex: number) => {
    if (optionIndex && optionIndex >= 0) {
      const idx = Math.floor(optionIndex) % 12;

      setStartRotationDegrees(getRotationDegrees(idx, sectors, false));

      setData({
        startRotationDegrees: getRotationDegrees(idx, sectors),
        finalRotationDegrees: getRotationDegrees(idx, sectors),
        startSpinningTime,
        continueSpinningTime,
        stopSpinningTime,
        disableInitialAnimation: false,
        time: classKey,
      });
    }
  };

  return (
    <div
      ref={mainContainerRef}
      className="flex flex-col gap-4 justify-center items-center overflow-hidden w-full z-10"
    >
      <div className="relative w-max h-max">
        <img
          className="absolute top-0 left-1/2 transform -translate-x-1/2 z-10 р-6"
          style={{ width: (70 * size) / 400 }}
          src="/pointer.svg"
        />

        <RotationContainer
          ref={rotationContainerRef}
          data={data}
          className={`wheel ${getRouletteClass()}`}
        >
          <canvas
            ref={canvasRef}
            width={size * 2}
            height={size * 2}
            style={{
              width: size,
              height: size,
            }}
          />
        </RotationContainer>
      </div>
    </div>
  );
}
