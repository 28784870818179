export const getRotationDegrees = (
  prizeNumber: number,
  numberOfPrizes: number,
  randomDif?: boolean
): number => {
  const degreesPerPrize = 360 / numberOfPrizes;

  const initialRotation = degreesPerPrize / 2;

  const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35;

  const perfectRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) -
    initialRotation -
    degreesPerPrize * 2;

  const imperfectRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) -
    initialRotation +
    randomDifference - degreesPerPrize * 2;

  const prizeRotation =
    randomDif === false ? perfectRotation : imperfectRotation;

  return numberOfPrizes - prizeNumber > numberOfPrizes / 2
    ? -360 + prizeRotation
    : prizeRotation;
};
