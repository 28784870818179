import { useEffect, useState } from "react";
import { TopButton } from "./TopButtons";

interface Props {
  onBack?: () => void;
  onInfo?: () => void;
  title: string;
}

const width = "35px";

export function Header(props: Props) {
  const { onBack, onInfo, title } = props;

  return (
    <header className="select-none flex flex-row justify-between w-full items-center">
      {onBack ? (
        <TopButton icon="/top-back.svg" onClick={onBack} />
      ) : (
        <div style={{ width }} />
      )}
      <h1 className="text-md font-bold text-white pixel">{title}</h1>
      {onInfo ? (
        <TopButton icon="/top-question.svg" onClick={onInfo} />
      ) : (
        <div style={{ width }} />
      )}
    </header>
  );
}
