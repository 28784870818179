export const FULL_LOOTBOX = 999999123;

const colorText = "(For Space Odyssey)";

export const prizes: any = {
  2: {
    name: "Lootbox Part",
    image: "/lootbox.png",
  },
  999999123: {
    name: "Lootbox",
    image: "/lootbox.png",
    text: "(Open in Punk City Inventory)",
  },
  1: {
    name: "Rocket Part",
    image: "/rocket.png",
  },
  3: {
    name: "Admin Nickname",
    image: "/colornick.png",
  },
  4: {
    name: "Extra Spin",
    image: "/wheel.png",
  },
  10: {
    name: "0.1 $PUNK",
    image: "/punk.png",
  },
  11: {
    name: "Blue Nickname",
    image: "/colornick.png",
    color: "#6699FF",
    text: colorText,
  },
  12: {
    name: "Aqua Nickname",
    image: "/colornick.png",
    color: "#66FFFF",
    text: colorText,
  },
  13: {
    name: "Red Nickname",
    image: "/colornick.png",
    color: "#FF3333",
    text: colorText,
  },
  14: {
    name: "Pink Nickname",
    image: "/colornick.png",
    color: "#FF66CC",
    text: colorText,
  },
  15: {
    name: "Purple Nickname",
    image: "/colornick.png",
    color: "#9966FF",
    text: colorText,
  },
  5: {
    name: "Iliyaeco",
    image: "/rockets/5-blue.svg",
    rarity: "blue",
  },
  6: {
    name: "Tonografia",
    image: "/rockets/6-blue.svg",
    rarity: "blue",
  },
  7: {
    name: "Anddrew21",
    image: "/rockets/7-blue.svg",
    rarity: "blue",
  },
  8: {
    name: "Inc77",
    image: "/rockets/8-purple.svg",
    rarity: "purple",
  },
  9: {
    name: "Attwoodse",
    image: "/rockets/9-purple.svg",
    rarity: "purple",
  },
  21: {
    name: "Walonedr",
    image: "/rockets/21-purple.svg",
    rarity: "purple",
  },
  16: {
    name: "6881149436",
    image: "/rockets/16-pink.svg",
    rarity: "pink",
  },
  17: {
    name: "Mindmesh",
    image: "/rockets/17-pink.svg",
    rarity: "pink",
  },
  18: {
    name: "Amanitalovers",
    image: "/rockets/18-red.svg",
    rarity: "red",
  },
  19: {
    name: "CryptoFreeBooter",
    image: "/rockets/19-red.svg",
    rarity: "red",
  },
  20: {
    name: "Golden Fleece",
    image: "/rockets/20-gold.svg",
    rarity: "gold",
  },
};

export var imagesToLoad: string[] = Object.values(prizes).map(
  (prize: any) => prize.image
);

export var preloadChill: string[] = [
  "/g-blue.png",
  "/g-gold.png",
  "/g-pink.png",
  "/g-purple.png",
  "/g-red.png",
  "/g-wheel.png",
];
