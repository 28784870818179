import { prizes } from "../config"

export const itemsArray: number[] = [
  5, 6, 7, 8, 9, 16, 17, 18, 19, 20
]


export const itemProps: { [key: number]: any } = {}

itemsArray.forEach((id: number) => {
  itemProps[id] = {...prizes[id], weapon: 'Rocket'}
})


