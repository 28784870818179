import { useEffect, useState } from "react";
import { Roulette, totalSpinningTime } from "./roulette/wheel";
import { RotationLight } from "./roulette/style";
import { ProgressItem } from "./ProgressItem";
import { Button } from "./button";
import { TopButton } from "./TopButtons";
import { Header } from "./Header";
import { WheelPrize, Wheel as WheelType } from "../logic/types";
import server from "../logic/server";

interface Props {
  setIsLoaded: (isLoaded: boolean) => void;
  setPage: (page: string) => void;
  isUpdate: boolean;
  wheel?: WheelType;
  wheelPrize?: WheelPrize;
}
export function Wheel(props: Props) {
  const { setIsLoaded, setPage, isUpdate, wheel, wheelPrize } = props;

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  let [timeBeforeNextSpin, setTimeBeforeNextSpin] = useState<number>();
  const [onBack, setOnBack] = useState<() => void>();
  const [isLootboxPrize, setIsLootboxPrize] = useState(false);

  const handleSpinClick = (item_id: number) => {
    if (!mustSpin) {
      setPrizeNumber(item_id);
      setMustSpin(true);
    }
  };

  useEffect(() => {
    if (!wheelPrize?.isLoading && wheelPrize?.item_id) {
      handleSpinClick(wheelPrize.item_id);
    }
  }, [wheelPrize]);

  useEffect(() => {
    if (!wheel) return;

    const redirect = localStorage.getItem("redirect");
    if (redirect) {
      setOnBack(() => () => {
        window.open(redirect, "_self");
      });
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        const url =
          "https://odyssey.pfplabs.xyz/?token=" + token + "&locale=en";
        setOnBack(() => () => {
          window.open(url, "_self");
        });
      }
    }

    let utcLastSpinString = wheel.res_last_free_at;
    const utcNow = new Date().getTime();
    const utcLastSpin = new Date(utcLastSpinString).getTime();
    const needToWait = 3 * 24 * 60 * 60 * 1000;
    const timeBeforeNextSpin = utcLastSpin + needToWait - utcNow;

    setTimeBeforeNextSpin(timeBeforeNextSpin);

    const func = () => {
      const utcNow = new Date().getTime();
      const timeBeforeNextSpin = utcLastSpin + needToWait - utcNow;
      setTimeBeforeNextSpin(timeBeforeNextSpin);
    };

    func();

    const timer = setInterval(() => {
      func();
    }, 30 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [wheel]);

  return (
    <>
      <Header
        title="Spinning Wheel"
        onBack={onBack}
        onInfo={() => {
          if (mustSpin) return;
          setPage("info");
        }}
      />
      <div className="select-none text-white text-center mt-0 text-sm mb-2">
        <div>Next free spin arrives</div>
        <div>
          {!timeBeforeNextSpin ? (
            "Loading..."
          ) : (
            <>
              {Math.floor(timeBeforeNextSpin / (1000 * 60 * 60 * 24))} days{" "}
              {Math.floor((timeBeforeNextSpin / (1000 * 60 * 60)) % 24)} hours{" "}
              {Math.floor((timeBeforeNextSpin / (1000 * 60)) % 60)} minutes
            </>
          )}
        </div>
      </div>
      <div
        className="select-none flex flex-col justify-center items-center w-full p-4 h-max p-0 relative bg-[url('/public/bg-wheel.png')] bg-contain bg-center bg-no-repeat"
        style={{
          marginTop: "0",
        }}
      >
        <Roulette
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          spinDuration={0.3}
          startingOptionIndex={0}
          isUpdate={isUpdate}
          setIsLoaded={setIsLoaded}
          onStopSpinning={() => {
            setMustSpin(false);
            setPage("prize");
            server?.onWheelEnd?.();
          }}
          hasStoppedSpinning={hasStoppedSpinning}
          setHasStoppedSpinning={setHasStoppedSpinning}
        />
        <RotationLight
          color="blue"
          src="/g-wheel.png"
          className={mustSpin ? "started-spinning" : ""}
          totalSpinningTime={totalSpinningTime}
        />
      </div>

      <div className="flex flex-row justify-between w-full items-center h-18 mt-1 gap-4 z-20 select-none">
        <ProgressItem
          icon="/rocket.png"
          maxDivisions={3}
          divisions={wheel?.res_rocket_part_amount || 0}
          onClick={() => {
            if (mustSpin) return;

            if (
              wheel?.res_rocket_part_amount &&
              wheel.res_rocket_part_amount >= 3
            ) {
              server.onRocketCaseOpen();
              setPage("case");
            }
          }}
        />
        <ProgressItem
          icon="/lootbox.png"
          maxDivisions={5}
          divisions={wheel?.res_case_part_amount || 0}
          isLoading={wheelPrize?.isLootboxLoading}
          onClick={() => {
            if (mustSpin || wheelPrize?.isLootboxLoading) return;

            if (
              wheel?.res_case_part_amount &&
              wheel.res_case_part_amount >= 5
            ) {
              server.onLootboxOpen(() => setPage("prize"));
            }
          }}
        />
      </div>

      <Button
        height="80px"
        buttonColor=""
        shadow={true}
        shadowColor="#2D0291"
        style={{ marginTop: 14 }}
        onClick={() => {
          if (mustSpin) {
            setMustSpin(false);
            setHasStoppedSpinning(true);
            return;
          }

          if (
            !wheelPrize?.isLoading &&
            wheel?.res_spins_amount &&
            wheel?.res_spins_amount > 0
          ) {
            server.onSpin();
          }
        }}
      >
        <img src="/wheel.png" alt="wheel" className="h-10 w-10 select-none" />
        <div className="flex flex-col justify-center items-center">
          <p className="text-white text-md font-bold select-none">
            {wheelPrize?.isLoading ? "Loading..." : mustSpin ? "Skip" : "Spin"}
          </p>
          {!mustSpin && (
            <p
              className="text-white text-sm select-none"
              style={{ color: "#CDBCF5" }}
            >
              {`(${
                wheel?.res_spins_amount ? wheel?.res_spins_amount : 0
              } left)`}
            </p>
          )}
        </div>
        <img src="/wheel.png" alt="wheel" className="h-10 w-10 select-none" />
      </Button>

      <Button
        height="45px"
        buttonColor="#2b1991"
        shadowColor="#250f6b"
        style={{ marginTop: 10 }}
        onClick={() => {
          if (mustSpin) return;
          setPage("moreSpins");
        }}
      >
        <p className="select-none text-white text-md">🎡 Get more spins</p>
      </Button>
    </>
  );
}
