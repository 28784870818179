import React from 'react';

export enum ButtonVariant {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
}

export const buttonColors = {
    primary :"#5820DD",
    secondary: "#0E042B",
    tertiary: "#0E042B",
    textPrimary: "#2D0291",
    textTertiary: "#2D0291",
    textSecondary: "#2D0291",
    textPrimaryDark: "#2D0291",
    primaryDark: "#2D0291",
    secondaryDark: "#5820DD",
    textSecondaryDark: "#2D0291",
    primaryLight: "#2D0291",
    buttonTertiaryLight: "#2D0291",
    secondaryLight: "#2D0291",
    buttonTertiary: "#2D0291",
    shadow: "#2D0291",
    redShadow: "#2D0291",

    black: "#0E042B",
    default: "#2D0291",
    light: "#5820DD",


}

export enum ButtonSize {
    small = 'small',
    default = 'default',
    large = 'large',
}

export enum ButtonForm {
    default = 'default',
    square = 'square',
}

export type ButtonProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    icon?: React.ReactNode | null;
    variant?: ButtonVariant;
    size?: ButtonSize;
    shadow?: boolean;
    maxWidth?: boolean;
    form?: ButtonForm;
    height?: string;
    buttonColor?: string;
    shadowColor?: string;
};
