import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { WheelPrize, Wheel as WheelType } from './logic/types';
import server from './logic/server';
import { Loader } from './components/Loader';
import { Wheel } from './components/Wheel';
import { Case } from './components/Case';
import { Info } from './components/Info';
import { Prize } from './components/Prize';
import { MoreSpins } from './components/MoreSpins';

function App() {
  let [isUpdate, setIsUpdate] = useState(false);
  let [isLoaded, setIsLoaded] = useState(false);
  let [page, setPage] = useState("wheel");

  let [isOpeningRocket, setIsOpeningRocket] = useState(false);

  const [wheel, setWheel] = useState<WheelType>();
  const [wheelPrize, setWheelPrize] = useState<WheelPrize>();

  useEffect(() => {
    server.setWheel = setWheel;
    server.setWheelPrize = setWheelPrize;
  }, []);

  useEffect(() => {
    server.onAuth();
  }, [])

  return (
    <main className="absolute top-0 left-0 w-full h-full flex flex-col items-center p-4 bg-black bg-gradient-to-b from-purple-light to-purple-empty overflow-y-scroll overflow-x-hidden removeScrollbarClass">
      {(!isLoaded || !wheel) && <Loader />}
      <div className="flex flex-col justify-center items-center w-full max-w-sm">
        {page === "wheel" && (
          <Wheel
            isUpdate={isUpdate}
            setIsLoaded={setIsLoaded}
            setPage={setPage}
            wheel={wheel}
            wheelPrize={wheelPrize}
          />
        )}
        {page === "case" && <Case setPage={setPage} wheelPrize={wheelPrize}/>}
        {page === "info" && <Info setPage={setPage} />}
        {page === "prize" && <Prize setPage={setPage} wheelPrize={wheelPrize} setWheelPrize={setWheelPrize} wheel={wheel} />}
        {page === "moreSpins" && <MoreSpins setPage={setPage} />}
      </div>
    </main>
  );
}

export default App;
