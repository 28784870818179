import { Header } from "./Header";
import { Button, ButtonSize } from "./button";
import { OpeningCase } from "./case/OpeningCase";

interface Props {
  setPage: (page: string) => void;
}

export function MoreSpins(props: Props) {
  const { setPage } = props;

  return (
    <>
      <Header title="Get More Spins" onBack={() => setPage("wheel")} />
      <div
        className="text-white flex flex-col justify-center gap-5 items-center w-full pl-4 pr-5 py-6 relative bg-[url('/public/bg-wheel.png')] bg-contain bg-no-repeat"
        style={{
          marginTop: "20px",
          backgroundSize: "100% 100%",
        }}
      >
        <Row
          icon="/pepe.png"
          title="Invite friends"
          text="Get a spin for each friend playing a $PUNK-betted match or Space Odyssey round"
          onClick={() => {
            const url = "https://t.me/SpaceOdysseyPlay_bot?start=Njg1NzU1OTYyNw==";
            openLink(url);
          }}
        />
        <Row
          icon="/odyssey.png"
          title="Play Space Odyssey"
          text="1 spin per 75 $PUNK used in Space Odyssey"
          onClick={() => {
            const url = "https://t.me/SpaceOdysseyPlay_bot?start=Njg1NzU1OTYyNw==";
            openLink(url);
          }}
        />
        <Row
          icon="/cyberarena.png"
          title="Play CyberArena"
          text="1 spin per 10 $PUNK 
          used on CyberArena"
          onClick={() => {
            const url =
              "https://t.me/punkcity2094bot?start=eyJ0eXBlIjoiaW52aXRlIiwidXNlcl9pZCI6MzMyODc1fQ==";
            openLink(url);
          }}
        />
      </div>
    </>
  );
}

function Row(props: {
  icon: string;
  title: string;
  text: string;
  onClick: () => void;
}) {
  const { icon, title, text, onClick } = props;
  return (
    <Button
      size={ButtonSize.large}
      shadowColor="#5820DD"
      buttonColor="#2D0291"
      height="100%"
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-center h-full gap-2 mt-1 mb-1">
        <img
          src={icon}
          alt="rocket"
          className="ml-2 mr-2 w-12 h-20 object-contain"
        />
        <div className="flex flex-col justify-center items-start text-white h-full">
          <p className="text-md font-medium">{title}</p>
          <p className="text-xs font-light">{text}</p>
        </div>
        <button className="rounded-md mr-1 shrink-0 h-12">
          <img
            src="/more-spins-button.png"
            className="object-contain shrink-0"
            style={{
              width: 40,
              height: 40,
            }}
            alt="more-spins"
          />
        </button>
      </div>
    </Button>
  );
}

function openLink(url: string) {
  if ((window as any)?.Telegram?.WebApp?.openTelegramLink) {
    (window as any)?.Telegram?.WebApp?.openTelegramLink(url);
  } else {
    window.open(url);
  }
}
