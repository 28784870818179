import { FULL_LOOTBOX } from "../components/config";
import { AuthData, Wheel, WheelPrize, WheelPrizeData } from "./types";

const TEST_INIT_DATA = "TEST_INIT_DATA";

class Server {
  endpointUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : "https://ton-rocket-server.pfplabs.xyz";

  browserInitData?: {
    token: string;
    locale: string;
  };

  punkCityToken?: string;

  wheel?: Wheel;
  setWheel?: (wheel: Wheel) => void;
  onWheelEnd?: () => void;
  onRocketCaseEnd?: () => void;

  wheelPrize?: WheelPrize;
  setWheelPrize?: (prize: WheelPrize) => void;

  telegramInitData?: string;

  attempts = 0;
  async onAuth() {
    console.log("V 1.0.2");
    
    await this.setTelegramInitData();
    this.setBrowserData();
    const data: AuthData = await this.post("/wheel/auth", {
      initData: this.telegramInitData,
      browserInitData: this.browserInitData,
      punkCityToken: this.punkCityToken,
      link: window?.location?.href,
    }).catch((err) => {
      // retry 10 times every 3 secs
      if (this.attempts < 10) {
        this.attempts++;
        setTimeout(() => {
          this.onAuth();
        }, 3000);
      }

      console.log(err);
    });

    if (!data || !data.token || !data.wheel) return;

    localStorage.setItem("token", data.token);
    localStorage.setItem("locale", "en");

    this.wheel = data.wheel;
    this.updateWheel();
  }

  async onSpin() {
    this.wheelPrize = { isLoading: true };
    this.updateWheelPrize();

    const token = localStorage.getItem("token");
    if (!token) return;

    const data: WheelPrizeData = await this.post("/wheel/spin", {
      token,
    }).catch((err) => {
      console.log(err);
    });

    if (!data || !data.item_id) return;

    this.wheelPrize = { item_id: data.item_id, isLoading: false };

    this.onWheelEnd = () => {
      if (!this.wheel) return;

      if (data.item_id === 1) {
        this.wheel.res_rocket_part_amount++;
      }
      if (data.item_id === 2) {
        this.wheel.res_case_part_amount++;
      }
      if (data.item_id === 4) {
        this.wheel.res_spins_amount++;
      }

      this.wheel.res_spins_amount--;

      this.updateWheel();

      this.onWheelEnd = undefined;
    };

    this.updateWheelPrize();
    this.updateWheel();
  }

  async onRocketCaseOpen() {
    this.wheelPrize = { isRocketLoading: true };
    this.updateWheelPrize();

    const token = localStorage.getItem("token");
    if (!token) return;

    const data: WheelPrizeData = await this.post("/wheel/spin-rocket", {
      token,
    }).catch((err) => {
      console.log(err);
    });

    if (!data || !data.item_id) return;

    this.wheelPrize = { item_id: data.item_id, isRocketLoading: false };

    this.onRocketCaseEnd = () => {
      if (!this.wheel) return;

      this.wheel.res_rocket_part_amount -= 3;

      this.updateWheel();

      this.onRocketCaseEnd = undefined;
    };

    this.updateWheelPrize();
    this.updateWheel();
  }

  async onLootboxOpen(callback: any) {
    const token = localStorage.getItem("token");
    if (!token) return;

    this?.setWheelPrize?.({ isLootboxLoading: true });

    const data = await this.post("/wheel/get-lootbox", { token }).catch(
      (err) => {
        console.log(err);
      }
    );

    if (data.amount === null || data.amount === undefined || !this.wheel)
      return;

    this.wheel.res_case_part_amount = data.amount;

    this.updateWheel();
    this?.setWheelPrize?.({ item_id: FULL_LOOTBOX, isLootboxLoading: false });
    callback();
  }

  async post(url: string, data: any) {
    return fetch(`${this.endpointUrl}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res?.json());
  }

  // UPDATES

  updateWheel() {
    if (!this.wheel) return;
    this.wheel = { ...this.wheel };

    if (this.setWheel) this.setWheel(this.wheel);
  }

  updateWheelPrize() {
    if (!this.wheelPrize) return;
    this.wheelPrize = { ...this.wheelPrize };

    if (this.setWheelPrize) this.setWheelPrize(this.wheelPrize);
  }

  async setTelegramInitData() {
    if (typeof window !== "undefined") {
      let initData = await (window as any)?.Telegram?.WebApp?.initData;
      // console.log(JSON.stringify(initData));
      if (!initData) {
        console.log("UPDATING AGAIN");
        // get test param
        const urlParams = new URLSearchParams(window.location.search);
        const test = urlParams.get("test");
        if (test) initData = TEST_INIT_DATA;
      }

      this.telegramInitData = initData;
    }
  }

  setBrowserData() {
    // get token & locale from params
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const locale = urlParams.get("locale");
    const redirect = urlParams.get("redirect");
    const punkCityToken = urlParams.get("punk_city_token");

    if (punkCityToken) {
      this.punkCityToken = punkCityToken;
    }

    if (redirect)
      localStorage.setItem("redirect", decodeURIComponent(redirect));

    if (token && locale) {
      this.browserInitData = { token, locale };

      localStorage.setItem("token", token);
      localStorage.setItem("locale", locale);

      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      // try to get from local storage
      const token = localStorage.getItem("token");
      const locale = localStorage.getItem("locale");
      if (token && locale) {
        this.browserInitData = { token, locale };
      }

      console.log(this.browserInitData);
    }
  }
}

export default new Server();
