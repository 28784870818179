import { Header } from "./Header";
import { OpeningCase } from "./case/OpeningCase";

interface Props {
  setPage: (page: string) => void;
}

export function Info(props: Props) {
  const { setPage } = props;

  return (
    <>
    <Header title="Rules" onBack={() => setPage("wheel")} />
      <div
        className="text-white flex flex-col justify-center items-center w-full px-4 py-6 relative bg-contain bg-no-repeat"
        style={{
          marginTop: "20px",
          backgroundSize: '100% 100%'
        }}
      >
        <p>I. You get a <span className="text-purple-text">free spin every 3 days</span>. There are several ways to get more spins, such as inviting friends or playing Punk City games.</p>
        <p className="mt-4">II. Loot box and Space Odyssey skins require <span className="text-purple-text">multiple parts to claim</span>. Progress on these rewards is displayed below the wheel. Once you collect all required parts, click on the green button to claim your prize.</p>
        <p className="mt-4">III. Weapons dropped from loot box claimed through the wheel will be <span className="text-purple-text">unavailable for trading</span>.</p>
      </div>
    </>
  );
}
